import React, { useState } from "react";
import LightBulb from "../assets/LightBulb";


function Switch({toggleTheme, isDarkTheme}) {
  const [isToggled, setIsToggled] = useState(isDarkTheme);

  const onToggle = () => {
    setIsToggled(!isToggled);
    toggleTheme();
  };
  return (
    <>
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <LightBulb/>
    </label>
    </>
  );
}
export default Switch;
