import { useTheme } from "styled-components";

function LightBulb() {
  const theme = useTheme()
 
  return (
    <svg  viewBox="0 0 80.4 92.2" width="40" height="40">
      <title>Switch Theme: ON/OFF</title>
        <path fill={theme.icon} d="M41.9,27.1c0-1.1-0.9-2-2-2c-1.4,0-2.8,0.2-4.2,0.6c-4,1.2-7.1,4.2-8.7,8c-0.4,1,0.1,2.2,1.1,2.6c0.2,0.1,0.5,0.1,0.8,0.2
          c0.8,0,1.5-0.5,1.8-1.2c1.1-2.7,3.4-4.8,6.2-5.7c1-0.3,2-0.5,3-0.5C41,29,41.9,28.2,41.9,27.1z"/>
        <path fill={theme.icon} d="M39.9,16.9c-12.2,0-22,9.8-22,22c0,9.2,2.9,13.2,7,17.8c1.2,1.4,2.1,2.9,2.8,4.6h0.1l3.9-0.3h0.1c-0.9-2.5-2.2-4.8-4-6.8
          c-3.2-3.6-6-6.8-6-15.2c0-9.9,8.1-18,18-18s18,8.1,18,18c0,8.4-2.8,11.6-6,15.2c-1.5,1.7-2.8,3.5-3.8,5.5l5-0.4
          c0.5-0.9,1.1-1.7,1.7-2.4c4.1-4.6,7-8.6,7-17.8C61.8,26.8,52.1,16.9,39.9,16.9z"/>
        <path fill={theme.icon} d="M51.6,62.5l-23.7,2c-1.1,0-2,1-1.9,2.1c0,1.1,1,2,2.1,1.9h0.2l23.7-2c1.1-0.1,1.9-1.1,1.8-2.2
          C53.7,63.2,52.7,62.4,51.6,62.5z"/>
        <path fill={theme.icon} d="M51.6,69.2l-23.7,2c-1.1,0-2,1-1.9,2.1c0,1.1,1,2,2.1,1.9h0.2l23.7-2c1.1-0.1,1.9-1.1,1.8-2.2C53.7,70,52.7,69.2,51.6,69.2z
          "/>
        <path fill={theme.icon} d="M40.6,86c-1.4,0-2.7-0.5-3.8-1.5l-4.6,0.4c2.4,4.6,8.1,6.5,12.7,4.1c2.2-1.1,3.9-3.1,4.7-5.5l-4.6,0.4
          C43.9,85.2,42.3,86,40.6,86z"/>
        <path fill={theme.icon} d="M51.6,76l-23.7,2c-1.1,0-2,1-1.9,2.1c0,1.1,1,2,2.1,1.9h0.2l23.7-2c1.1-0.1,1.9-1.1,1.8-2.2C53.7,76.7,52.7,75.9,51.6,76
          L51.6,76z"/>
        <path fill={theme.icon} d="M19.8,60.6c-0.7-0.7-1.7-0.7-2.5-0.1l-5.8,5.2c-0.7,0.7-0.8,1.8-0.1,2.6c0,0,0,0,0.1,0.1c0.2,0.2,0.5,0.5,0.7,0.7
          c0.7,0.7,1.9,0.7,2.6,0c0,0,0,0,0.1-0.1l5.1-5.8C20.6,62.4,20.6,61.3,19.8,60.6L19.8,60.6z"/>
        <path fill={theme.icon} d="M13.6,51.3L13.6,51.3c-0.4-0.9-1.4-1.3-2.2-1L4,52.8c-0.9,0.3-1.4,1.4-1.1,2.3c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.6,0.4,0.9
          c0.4,0.9,1.5,1.3,2.4,1c0,0,0,0,0.1,0l7-3.4C13.6,53.2,14,52.2,13.6,51.3z"/>
        <path fill={theme.icon} d="M11.4,41.9c0-0.6,0-1.2,0-1.8c0-1-0.7-1.7-1.7-1.8l-7.8-0.5c-1-0.1-1.9,0.7-1.9,1.7c0,0,0,0.1,0,0.1v1c0,1,0.8,1.8,1.8,1.8
          c0,0,0,0,0.1,0l9.5-0.5L11.4,41.9z"/>
        <path fill={theme.icon} d="M4,27.6l7.3,2.5c0.9,0.3,1.9-0.1,2.2-1l0,0c0.4-0.9,0-1.9-0.9-2.3l-7-3.4c-0.9-0.4-2-0.1-2.4,0.8c0,0,0,0.1,0,0.1
          C3.1,24.7,3,25,2.8,25.3C2.5,26.2,3,27.3,4,27.6C3.9,27.6,3.9,27.6,4,27.6z"/>
        <path fill={theme.icon} d="M66.8,29.1L66.8,29.1c0.4,0.9,1.4,1.3,2.2,1l7.3-2.5c0.9-0.3,1.4-1.4,1.1-2.3c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.6-0.4-0.9
          c-0.4-0.9-1.5-1.4-2.4-1c0,0,0,0-0.1,0l-7,3.4C66.7,27.1,66.4,28.2,66.8,29.1z"/>
        <path fill={theme.icon} d="M17.3,19.9c0.7,0.6,1.8,0.6,2.5-0.1l0,0c0.7-0.7,0.7-1.8,0.1-2.5l-5.2-5.8c-0.7-0.7-1.8-0.8-2.6-0.1c0,0,0,0,0,0l-0.7,0.7
          c-0.7,0.7-0.7,1.9,0,2.6c0,0,0,0,0,0L17.3,19.9z"/>
        <path fill={theme.icon} d="M60.6,19.8c0.7,0.7,1.7,0.7,2.5,0.1l5.8-5.2c0.7-0.7,0.8-1.8,0.1-2.6c0,0,0,0-0.1-0.1c-0.2-0.2-0.5-0.5-0.7-0.7
          c-0.7-0.7-1.9-0.7-2.6,0c0,0,0,0-0.1,0.1l-5.2,5.8C59.8,18,59.8,19.1,60.6,19.8L60.6,19.8z"/>
        <path fill={theme.icon} d="M63.1,60.4c-0.7-0.6-1.8-0.6-2.5,0.1l0,0c-0.7,0.7-0.8,1.8-0.1,2.5c0,0,0,0,0,0l5.2,5.8c0.7,0.7,1.8,0.8,2.5,0.2
          c0,0,0.1,0,0.1-0.1c0.2-0.2,0.5-0.5,0.7-0.7c0.7-0.7,0.7-1.9,0-2.6c0,0,0,0-0.1-0.1L63.1,60.4z"/>
        <path fill={theme.icon} d="M76.4,52.6l-7.3-2.5c-0.9-0.3-1.9,0.1-2.2,1l0,0c-0.4,0.9,0,1.9,0.9,2.3l7,3.4c0.9,0.5,2,0.1,2.4-0.8c0,0,0-0.1,0-0.1
          c0.1-0.3,0.3-0.6,0.4-0.9c0.4-0.9-0.1-2-1-2.4C76.5,52.6,76.5,52.6,76.4,52.6z"/>
        <path fill={theme.icon} d="M80.4,39.6c0-1-0.8-1.8-1.8-1.8c0,0-0.1,0-0.1,0l-9.5,0.5v0.1c0,0.5,0,1.1,0,1.6c0,1,0.7,1.7,1.7,1.8l7.8,0.4
          c1,0.1,1.9-0.7,1.9-1.7c0,0,0-0.1,0-0.1L80.4,39.6z"/>
        <path fill={theme.icon} d="M51.2,13.5L51.2,13.5c0.9,0.4,1.9,0,2.3-0.9l3.4-7c0.4-0.9,0.1-2-0.8-2.4c0,0-0.1,0-0.1,0l-0.9-0.4c-0.9-0.4-2,0.1-2.4,1
          c0,0,0,0.1,0,0.1l-2.5,7.3C49.9,12.1,50.3,13.1,51.2,13.5z"/>
        <path fill={theme.icon} d="M26.8,12.7c0.4,0.9,1.4,1.2,2.3,0.9l0,0c0.9-0.4,1.3-1.3,1-2.2l-2.5-7.4c-0.3-0.9-1.4-1.4-2.3-1.1c0,0-0.1,0-0.1,0l-0.9,0.4
          c-0.9,0.4-1.4,1.5-1,2.4c0,0,0,0,0,0.1L26.8,12.7z"/>
        <path fill={theme.icon} d="M40.2,11.3L40.2,11.3c1,0,1.7-0.7,1.8-1.7l0.5-7.7c0.1-1-0.7-1.8-1.7-1.9c0,0-0.1,0-0.1,0h-1c-1,0-1.8,0.8-1.8,1.8
          c0,0,0,0.1,0,0.1l0.5,7.7C38.4,10.6,39.2,11.3,40.2,11.3z"/>
        </svg>
  );
}

export default LightBulb;
